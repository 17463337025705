<script setup>
import { computed } from 'vue'

const props = defineProps({
    type: {
        type: String,
        default: 'Submit',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    color: {
        type: String,
        default: 'logix-green',
    },
})

const buttonStyles = computed(() => {
    return [
        'inline-flex items-center px-4 py-2 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 transition',
        `bg-${props.color}`,
        props.disabled ? 'opacity-25' : `hover:bg-${props.color}`,
    ]
})

const removeFocus = (event) => {
    event.target.blur()
}
</script>

<template>
    <button :type="type" :disabled="disabled" :class="buttonStyles" @click="removeFocus">
        <slot />
    </button>
</template>
